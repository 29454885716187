import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { InputAdornment } from '@mui/material';

import { INPUT_SIZES } from 'helpers/forms';

import Typography from 'components/ui/Atoms/Typography';
import IconButton from 'components/Buttons/IconButton';
import styles from './InputActionStyles';

const useStyles = makeStyles({ name: 'InputActions' })(styles);

const InputActions = (props) => {
  // Props
  const { inputActions, position, name, disabled, size } = props;

  // Hooks
  const { classes, cx } = useStyles();

  // Vars
  const hasClicks = inputActions.some(action => Boolean(action.onClick));

  // Func
  const getItem = (action) => {
    const { onClick, Icon, content, className, ...actionProps } = action;
    let item = null;

    if (onClick && Icon) {
      item = (
        <IconButton
          onClick={action.onClick}
          disabled={disabled}
          Icon={Icon}
          {...actionProps}
        />
      );
    } else if (Icon) {
      item = (
        <Icon
          className={cx(classes.icon, { [classes.disabledIcon]: disabled }, className)}
          {...actionProps}
        />
      );
    } else if (content) {
      item = (
        <Typography
          className={classes.text}
          component="span"
          variant={size === 'large' ? 'h4' : 'body2'}
        >
          {content}
        </Typography>
      );
    }

    return item;
  };

  return (
    <InputAdornment
      position={position}
      disablePointerEvents={!hasClicks || disabled}
      className={classes.root}
      component="ul"
      classes={{
        positionStart: classes.start,
        positionEnd: classes.end,
      }}
    >
      {inputActions.map((action, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={`input-action-${position}-${name}-${index}`} className={classes.item}>
          {getItem(action)}
        </li>
      ))}
    </InputAdornment>
  );
};

InputActions.propTypes = {
  inputActions: PropTypes.arrayOf(
    PropTypes.shape({
      onClick: PropTypes.func,
      Icon: PropTypes.object,
      content: PropTypes.string,
    }).isRequired,
  ).isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(INPUT_SIZES),
};

InputActions.defaultProps = {
  disabled: false,
  size: 'medium',
};

export default InputActions;
