import PropTypes from 'prop-types';
import { makeStyles } from 'tss-react/mui';
import { Input } from '@mui/material';

import { INPUT_SIZES } from 'helpers/forms';

import InputActions from './InputActions';
import styles from './BaseInputStyles';

const useStyles = makeStyles({ name: 'BaseInput' })(styles);

const BaseInput = (props) => {
  // Props
  const {
    'aria-label': ariaLabel,
    classes: classesFromProps,
    className,
    disabled,
    error,
    inputProps,
    multiline,
    size,
    inputPrefix,
    inputSuffix,
    name,
    fieldRef, // pass to the input wrapper - different from 'inputRef'
    isActive, // mimics the focus styling without it being focused
    ...otherProps
  } = props;

  // Hooks
  const { classes, cx } = useStyles();
  const showError = error && !disabled;

  return (
    <Input
      className={cx(
        classes.root,
        { [classes[size]]: INPUT_SIZES.includes(size) },
        { [classes.focused]: isActive },
        className,
      )}
      classes={{
        multiline: classes.multiline,
        input: classes.input,
        disabled: classes.disabled,
        focused: classes.focused,
        error: classes.error,
        ...classesFromProps,
      }}
      disableUnderline
      disabled={disabled}
      error={showError}
      inputProps={ariaLabel ? { 'aria-label': ariaLabel, ...inputProps } : inputProps}
      multiline={multiline}
      name={name}
      ref={fieldRef}
      startAdornment={(inputPrefix && inputPrefix.length > 0) && (
        <InputActions
          position="start"
          name={name}
          inputActions={inputPrefix}
          disabled={disabled}
          size={size}
        />
      )}
      endAdornment={(inputSuffix && inputSuffix.length > 0) && (
        <InputActions
          position="end"
          name={name}
          inputActions={inputSuffix}
          disabled={disabled}
          size={size}
        />
      )}
      {...otherProps}
    />
  );
};

BaseInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  'aria-label': PropTypes.string,
  autoComplete: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  fieldRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  error: PropTypes.bool,
  inputProps: PropTypes.object,
  isActive: PropTypes.bool,
  multiline: PropTypes.bool,
  inputPrefix: PropTypes.array,
  size: PropTypes.oneOf(INPUT_SIZES),
  inputSuffix: PropTypes.array,
};

BaseInput.defaultProps = {
  'aria-label': undefined,
  autoComplete: 'off',
  classes: undefined,
  className: undefined,
  disabled: false,
  fieldRef: undefined,
  error: undefined,
  inputProps: {},
  isActive: false,
  multiline: false,
  inputPrefix: undefined,
  size: 'medium',
  inputSuffix: undefined,
};

export default BaseInput;
